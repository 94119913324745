<template>
  <div class="notice-board-page">
    <m-page-title :title="title" address />
    <div class="content flex-1">
      <div class="scroll-x-box" :style="{ left: details.show ? '-100%' : 0 }">
        <div class="scroll-x-box-center">
          <div class="header flex-row j-sb a-center">
            <span class="notice">Notice</span>
            <span class="date">Released Date</span>
            <span class="date">End Date</span>
          </div>
          <div class="list-box g-scroll-style">
            <div
              class="items flex-row j-sb a-center g-pointer"
              v-for="(item, index) in noticeBoardList"
              :key="index"
              @click="toInfo(item)"
            >
              <span class="notice">
                {{ item.title }}
                <i
                  v-if="item.pdfParamList.length"
                  class="el-icon-paperclip"
                ></i>
              </span>
              <span class="date">
                <i class="el-icon-date"></i>
                {{ item.createTime | formatTimeDmy }}
              </span>
              <span class="date">
                <i class="el-icon-date"></i>
                {{ item.endTime | formatTimeDmy }}
              </span>
            </div>
          </div>

          <div class="btn-box">
            <el-form
              ref="From"
              :rules="rules"
              :model="form"
              v-if="sideBarStatus !== -1"
              @submit.native.prevent
            >
              <el-form-item prop="check">
                <el-checkbox class="check" v-model="form.check">
                  I have read the building information
                </el-checkbox>
              </el-form-item>
            </el-form>
            <div class="flex-row mt-1">
              <m-back v-if="sideBarStatus !== -1" />
              <el-button type="primary" @click="toDashboard">
                Go to dashboard
              </el-button>
            </div>
          </div>
        </div>
        <div class="scroll-x-box-center">
          <div class="details-box g-radius-4">
            <div class="details-title-box">
              <span class="notice">{{ details.data.title }}</span>
              <span class="date">
                <i class="el-icon-date"></i
                >{{ details.data.createTime | formatTimeDmy }}</span
              >
            </div>
            <div class="details-center g-scroll-style">
              <!-- <div v-html="details.data.content"></div> -->
              <div v-if="details.data.pdfParamList.length > 0">
                <div
                  class="collapse-title g-radius-4"
                  v-for="(citem, cindex) in details.data.pdfParamList"
                  :key="cindex"
                >
                  <span
                    @click="fileLinkToStreamDownload(citem.url, citem.name)"
                    class="downurl"
                    >{{ citem.name }}</span
                  >
                  <div class="btn-box-new">
                    <a
                      class="download"
                      href="javascript:;"
                      @click="fileLinkToStreamDownload(citem.url, citem.name)"
                      ><i class="el-icon-download"></i>Download</a
                    >
                    <a class="view" :href="citem.url" target="_blank"
                      ><i class="el-icon-paperclip"></i>View</a
                    >
                  </div>
                </div>
              </div>
              <div class="ql-container ql-snow">
                <div class="ql-editor" v-html="details.data.content"></div>
              </div>
            </div>
          </div>
          <m-back unBack @click="backClick" class="back" />
          <el-button type="primary" class="back-btn" @click="backClick">
            Back
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import validate from "@/utils/validateForm";

export default {
  name: "NoticeBoard",
  components: {},
  data() {
    return {
      // 查看详情
      details: {
        show: false,
        data: {
          pdfParamList: [],
        },
      },
      form: { check: false },
      rules: { check: [{ validator: validate.agreementConfirm }] },
      noticeBoardList: [],
    };
  },
  computed: {
    ...mapState("apartment", ["room"]),
    ...mapState("bar", ["sideBarStatus"]),
    title() {
      return this.details.show ? this.details.data.title : "Notice Board";
    },
  },
  created() {
    this.getList();
    this.keyupSubmit();
  },
  mounted() {},
  methods: {
    // enter事件
    keyupSubmit() {
      document.onkeydown = () => {
        const _key = window.event.keyCode;
        if (_key === 13) {
          if (this.sideBarStatus !== -1) {
            this.toDashboard();
          }
        }
      };
    },
    fileLinkToStreamDownload(url, name) {
      const fileName = name;
      const xhr = new XMLHttpRequest();
      xhr.open("get", url, true);
      xhr.setRequestHeader("Content-Type", `application/pdf`);
      xhr.responseType = "blob";
      const that = this;
      xhr.onload = function () {
        if (this.status === 200) {
          // 接受二进制文件流
          const blob = this.response;
          that.downloadExportFile(blob, fileName);
        }
      };
      xhr.send();
    },
    downloadExportFile(blob, tagFileName) {
      const downloadElement = document.createElement("a");
      const href = window.URL.createObjectURL(blob); // 创建下载的链接
      downloadElement.href = href;
      downloadElement.download = tagFileName; // 下载后文件名
      document.body.appendChild(downloadElement);
      downloadElement.click(); // 点击下载
      document.body.removeChild(downloadElement); // 下载完成移除元素
      window.URL.revokeObjectURL(href); // 释放blob对象
    },
    getList() {
      this.$axios
        .get(this.$api.noticeBoards(this.room.apartmentId), {
          params: { apartmentUnitId: this.room.apartmentUnitId },
        })
        .then((res) => {
          if (res.code === "1000") {
            this.noticeBoardList.splice(
              0,
              this.noticeBoardList.length,
              ...res.data.list
            );
          }
        });
    },
    // 查看公告详情
    toInfo(e) {
      this.$axios
        .get(this.$api.noticeBoard(e.id), {
          apartmentUnitId: this.room.apartmentUnitId,
        })
        .then((res) => {
          if (res.code === "1000") {
            this.details.data = res.data.apartmentNoticeBoard;
            this.details.show = true;
          }
        });
    },
    // 返回列表
    backClick() {
      this.details.show = false;
    },
    toDashboard() {
      if (this.sideBarStatus === -1) {
        this.$router.push("/");
      } else {
        this.$refs.From.validate().then(() => this.$router.push("/"));
      }
    },
  },
};
</script>

<style scoped lang="scss" src="./scss.scss"></style>
